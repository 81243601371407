<template>
  <div class="d-flex align-items-center justify-content-between d-wrap">
    <b-form-group class="col-md-2 col-xs-12" :label="translations.input.client_id">
      <b-form-select v-model="form.client_id" :disabled="disabled" :options="options" @change="onChangeClient"/>
    </b-form-group>

    <b-form-group class="col-md-2 col-xs-12" :label="translations.input.statement_start_date_from">
      <vue-ctk-date-time-picker format="YYYY-MM-DD"
        :label="translations.input.statement_start_date_from_placeholder"
        :disabled="disabled"
        formatted="ll"
        only-date
        v-model="form.statement_start_date"/>
    </b-form-group>
    <b-form-group class="col-md-2 col-xs-12" :label="translations.input.update">
      <b-form-checkbox v-model="form.refresh_insurance_info"/>
    </b-form-group>
    <b-form-group class="col-md-2 col-xs-12" :label="translations.input.regenerate_statements">
      <b-form-checkbox v-model="form.regenerate_statements"/>
    </b-form-group>
    <b-button class="col-md-2 col-xs-12 mt-3" variant="primary" @click="onSubmit" :disabled="disabled || isDisabled()">
      {{ translations.input.submit }}
    </b-button>
  </div>
</template>

<script>
import translations from '@/translations';
import service from '@/services/finance-service';
import { parseResponseError, parseResponseStatus } from '@/http/parsers/parse_response';

export default {
  name: 'UpdatesFormRequest',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      translations: translations.finance.updates,
      form: {
        client_id: null,
        client_name: '',
        statement_start_date: null,
        refresh_insurance_info: false,
        regenerate_statements: false,
      },
      clients: [],
      options: [],
    };
  },
  async beforeMount() {
    await this.loadFilters();
  },
  methods: {
    onChangeClient(opt) {
      const clients = this.clients.filter(c => c.id === opt);
      if (clients?.length) {
        this.form.client_name = clients[0].display_name || '';
      }
    },
    async onSubmit() {
      await this.submit({
        client_id: this.form.client_id,
        client_name: this.form.client_name,
        statement_start_date: this.form.statement_start_date,
        refresh_insurance_info: this.form.refresh_insurance_info,
        regenerate_statements: this.form.regenerate_statements,
      });
    },
    async retrySubmit(currentBody, shouldForce837 = false) {
      await this.submit({
        ...currentBody,
        force_regenerate_837: shouldForce837,
      });
    },
    async submit(body) {
      try {
        await service.postRefreshTransaction(body);
        this.$emit('refetch');
      } catch (err) {
        const errParsed = parseResponseError(err);
        const statusParsed = parseResponseStatus(err);

        if (statusParsed === 422 && errParsed?.includes('missing regenerate 837 file flag')) {
          const noForce837 = await this.$bvModal.msgBoxConfirm(this.translations.popup_force_update.message, {
            title: this.translations.popup_force_update.title,
            okTitle: this.translations.popup_force_update.btn_ok,
            cancelTitle: this.translations.popup_force_update.btn_cancel,
            cancelVariant: 'outline-primary',
          });
          // if noForce837 is true, it means the user don't want to force the regenerate 837 file
          this.retrySubmit(body, !noForce837);
          return;
        }

        this.$noty.error(this.translations.errors.fetch_request_update_error);
      }
    },
    async loadFilters() {
      try {
        const { data } = await this.$store.dispatch('Financial/getFilters');
        this.clients = data;
        this.options = data.map(c => ({ value: c.id, text: `${c.display_name} (ID: ${c.id})` }));
      } catch (_) {
        this.$noty.error(this.translations.errors.fetch_clients_loading_error);
      }
    },
    isDisabled() {
      if (this.form.client_id && this.form.statement_start_date) {
        return !this.form.regenerate_statements && !this.form.refresh_insurance_info;
      }
      return true;
    },
  },
};
</script>
