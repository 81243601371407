<template>
  <div>
    <b-spinner v-if="loading"/>
    <div v-else class="p-3">
      <header class="d-flex flex-row justify-content-between mb-1">
        <h3>{{ translations.updates.page_title }}</h3>
      </header>

      <div class="d-flex align-items-center mb-4">
        <div class="">
          <feather type="info" class="text-info" style="height: 30px; width: 30px" />
        </div>
        <div class="flex-1 ml-4">
          <p class="text-gray m-0" style="color: gray; font-size: small;">
            {{ translations.updates.page_helper_text_1 }}
          </p>
          <p class="text-gray m-0" style="color: gray; font-size: small;">
            {{ translations.updates.page_helper_text_2 }}
          </p>
          <p class="text-gray m-0" style="color: gray; font-size: small;">
            {{ translations.updates.page_helper_text_3 }}
          </p>
        </div>
      </div>

      <div>
        <updates-form-request :disabled="loading" @refetch="refetchUploads"/>
      </div>

      <div class="col-12">
        <div>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="text-center">{{ translations.updates.table.request_id }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.update_and_regenerate_statements }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.client_name }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.date_from }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.created_at }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.completed_at }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.created_by }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.status }}</th>
                <th scope="col" class="text-center">{{ translations.updates.table.actions }}</th>
              </tr>
            </thead>
            <tbody v-if="elements.length">
              <tr v-for="(item, index) in elements" :key="index">
                <td class="text-center">{{ item.id }}</td>
                <td class="text-center">{{ formatBoolean(item.refresh_insurance_info) }} / {{ formatBoolean(item.regenerate_statements) }}</td>
                <td class="text-center">{{ item.client_name }}</td>
                <td class="text-center">{{ formatDate(item.statement_start_date) }}</td>
                <td class="text-center">{{ formatDatetime(item.created_at) }}</td>
                <td class="text-center">{{ formatDatetime(item.completed_at) }}</td>
                <td class="text-center">{{ item.user_email }}</td>
                <td class="text-center">
                  <b-badge :variant="formatStatusLabel(item.status)">
                    <p class="badge-status text-capitalize m-0">{{ item.status }}</p>
                  </b-badge>
                </td>
                <td class="text-center">
                  <b-button v-b-tooltip.hover
                            size="sm"
                            :disabled="!isCompletedStatus(item.status)"
                            :title="translations.updates.actions_label.details"
                            variant="light"
                            class="btn-action"
                            @click="openDetails(item.id)">
                    <feather type="eye" />
                  </b-button>
                  <b-button v-if="isCompletedStatus(item.status)"
                            v-b-tooltip.hover
                            size="sm"
                            :title="translations.updates.actions_label.export"
                            variant="light"
                            class="btn-action"
                            @click="exportDetails(item)">
                    <feather type="download" />
                  </b-button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="8" class="text-center">{{ translations.updates.table.empty_list }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row pt-2">
          <div class="col-12 d-flex justify-content-center">
            <b-pagination :disabled="loading"
                          @change="changePage"
                          v-model="currentPage"
                          :total-rows="total"
                          :per-page="itemsPerPage"
                          size="md">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';
import UpdatesFormRequest from '@/views/Finance/Updates/UpdatesFormRequest.vue';
import utils from '@/scripts/tools/utils';
import { formatDate, formatDatetime } from '@/helpers/finance';

export default {
  name: 'FinanceUpdatesList',
  components: { UpdatesFormRequest },
  mixins: [ errorHandler ],
  data() {
    return {
      translations: translations.finance,
      loading: true,
      elements: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    await this.getRefreshTransactions();
  },
  methods: {
    formatDate,
    formatDatetime,
    changePage(page) {
      this.currentPage = page;
      this.getRefreshTransactions();
    },
    refetchUploads() {
      this.currentPage = 1;
      this.getRefreshTransactions();
    },
    async getRefreshTransactions() {
      this.loading = true;
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;

      try {
        const { items, total } = await this.$store.dispatch('Financial/getRefreshTransactions', {
          limit,
          offset,
          order_by: 'id',
          order_descending: 'true',
        });

        this.elements = items || [];
        this.total = total || 0;
      } catch (_) {
        this.$noty.error(`${this.translations.updates.errors.fetch_updates_loading_error}.`);
      } finally {
        this.loading = false;
      }
    },
    openDetails(id) {
      this.$router.push(`/finance/updates/${id}`);
    },
    async exportDetails(item) {
      try {
        const { data } = await this.$store.dispatch('Financial/exportTransactions', {
          event_date_start: formatDate(item.statement_start_date),
          event_date_end: formatDate(item.completed_at),
          client_id: item.client_id,
          order_by: 'id',
        });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `update_insurance_info_${dateStr}_${item.id}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error?.response?.data?.error?.detail) {
          errorMsg += `: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    formatStatusLabel(label) {
      if (this.isCompletedStatus(label)) {
        return 'success';
      }
      return 'secondary';
    },
    isCompletedStatus(status) {
      return status.toLowerCase() === 'completed';
    },
    formatBoolean(v) {
      return v ? 'Yes' : 'No';
    },
  },
};
</script>

<style>
.btn-action {
  min-width: 50px;
  margin-left: 8px;
}
</style>
